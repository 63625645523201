import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [8,[2]],
		"/(app)/admin": [9,[2]],
		"/(app)/admin/users": [10,[2]],
		"/(app)/admin/users/create": [11,[2]],
		"/(app)/athletes": [12,[2]],
		"/(app)/athletes/add": [15,[2]],
		"/(app)/athletes/[id]": [13,[2]],
		"/(app)/athletes/[id]/edit": [14,[2]],
		"/auth": [32,[5]],
		"/auth/email/error": [33,[5,6]],
		"/auth/email/error/browser": [34,[5,6]],
		"/auth/email/error/link": [35,[5,6]],
		"/auth/email/sent": [36,[5,6]],
		"/auth/entering/link": [37,[5]],
		"/dev": [38],
		"/dev/angleCalc": [39],
		"/dev/athletes": [40],
		"/dev/bananas": [41],
		"/dev/beep": [42],
		"/dev/benchmarking": [43],
		"/dev/benchmarking/datasctructures": [44],
		"/dev/demo": [45],
		"/dev/demo/videoFilterPOA": [46],
		"/dev/ffmpeg": [47],
		"/dev/filesystem": [48],
		"/dev/jsbuild": [49],
		"/dev/mediaSource": [50],
		"/dev/plots": [51],
		"/dev/plots/[tabNumber]": [52],
		"/dev/sentry": [53],
		"/dev/slotTest": [54],
		"/dev/tfjs": [55],
		"/dev/vanillaLoad": [56],
		"/dev/webCodecs": [57],
		"/(app)/help": [16,[2]],
		"/home": [58],
		"/(app)/invites": [17,[2]],
		"/(app)/lab": [18,[2,3]],
		"/(app)/lab/[...type]": [19,[2,3]],
		"/legal/privacy": [59,[7]],
		"/legal/terms": [60,[7]],
		"/(app)/sessions/new": [20,[2]],
		"/(app)/settings/app": [21,[2,4]],
		"/(app)/settings/subscriptions": [22,[2,4]],
		"/(app)/trainees": [23,[2]],
		"/(app)/trainer": [24,[2]],
		"/(app)/unsupported": [25,[2]],
		"/(app)/users/edit": [28,[2]],
		"/(app)/users/me": [29,[2]],
		"/(app)/users/[id]": [26,[2]],
		"/(app)/users/[id]/edit": [27,[2]],
		"/(app)/videos/[id]": [30,[2]],
		"/(app)/videos/[id]/edit": [31,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';